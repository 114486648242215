body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-header {
  border-end-start-radius: 50px;
  border-start-end-radius: 50px;
}

.app-bg-primary{
  background-color: #23A455 !important;
}
.app-text-primary {
  color: #23A455 !important;
}
.app-btn-primary {
  background-color: #23A455 !important;
}

.app-btn-primary:hover {
  background-color: #23A455 !important;
  color: white;
}


.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  left: 50%;
  top: 40%;
  right: 50%;
  height: 80px;
  animation: spin 1s linear infinite;
  position: absolute;
  
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

a {
  /* text-decoration: none; */
  /* text-decoration-line: none; */
}

.onHover:hover {
  color: #23A455 !important;
  
}


.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.parallax-home {
  /* The image used */
  background-image: url("./assets/images/mesjid.webp");

  /* Set a specific height */
  min-height: 500px;


  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.ellipsis {
  width: cover;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}